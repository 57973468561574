<template>
    <div class="classify_add_eidt">
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="1000px" :close-on-click-modal='false' @close="cancelBtn">
            <div slot="title">
                <div class="name">添加文档</div>
            </div>

            <div class="text_main">
                <div class="main_condition">
                    <div class="cond_left">
                        <el-input v-model="title" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' :clearable="true" placeholder="搜索文档名称"></el-input>
                        <el-cascader v-model="classify" :options="classifyOptions" :props="prop" :show-all-levels="false" size='small' :clearable="true" @clear='searchBtn' @change="searchBtn" placeholder="请选择分类"></el-cascader>
                    </div>
                </div>
                <!--  -->
                <div class="main_table">
                    <el-table :data="tableData" class="draggable-table" key="add_theme" row-key="id" v-loading="loading" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class" @selection-change="handleSelectionChange">
                        <template slot="empty">
                            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                        </template>
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="id" label="ID" width="80" align="center"></el-table-column>
                        <el-table-column prop="category.name" label="分类名称" width="200"></el-table-column>
                        <el-table-column prop="title" label="标题"></el-table-column>
                        <el-table-column prop="create_time" label="创建时间" width="250"></el-table-column>
                    </el-table>
                </div>
                <!-- 分页 -->
                <div class="page_box" v-if="total">
                    <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
                </div>
            </div>

            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
let that
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import Sortable from 'sortablejs'//列表拖动
export default {
    components: { EmptyData, PagingPage },
    props: {
        classifyOptions: Array,
        id: {
            type: [String, Number]
        },
    },
    data() {
        return {
            title: '',
            dialogVisible: false,
            // 
            classify: '',
            // 
            subLoading: false,
            // 
            tableData: [],
            loading: false,
            //
            page: 1,
            limit: 10,
            total: 0,
            multipleSelection: [],
            prop: {
                value: 'id',
                label: 'name',
                expandTrigger: 'hover',
                children: 'children',
                checkStrictly: true,
                emitPath: false
            },
        };
    },


    created() {
        that = this
    },

    mounted() {

    },

    methods: {
        initSort() {
            const tbody = document.querySelector(".draggable-table .el-table__body-wrapper tbody");
            new Sortable(tbody, {
                animation: 150,
                // 需要在odEnd方法中处理原始eltable数据，使原始数据与显示数据保持顺序一致
                onEnd: ({ newIndex, oldIndex }) => {
                    const targetRow = this.tableData[oldIndex];
                    this.tableData.splice(oldIndex, 1);
                    this.tableData.splice(newIndex, 0, targetRow);
                    // this.updateSort() 更新数据库，将排序后的数据传给后端
                },
            });
        },
        handleSelectionChange(val) {
            console.log(val)
            this.multipleSelection = val;
        },
        // 打开
        openDialog() {
            that.dialogVisible = true
            that.getList()
        },
        getList() {
            this.loading = true
            var params = {
                page: this.page,
                limit: this.limit,
                title: this.title,
                category_id: this.classify,
            }
            this.$helpApi.documentList(params).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.tableData = res.result.data
                    this.total = res.result.total
                    this.$nextTick(() => {
                        this.initSort()
                    })
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 取消
        cancelBtn() {
            this.dialogVisible = false
            this.tableData = []
            this.page = 1
            this.limit = 5
            this.total = 0
            this.multipleSelection = []
        },
        // 筛选搜索
        searchBtn() {
            this.page = 1
            this.total = 0
            this.getList()
        },

        // 确定
        affirmBtn() {
            if (this.multipleSelection.length == 0) {
                this.$errMsg('请选择文档')
                return
            }
            that.subLoading = true
            let arr = []
            this.multipleSelection.forEach(ele => {
                arr.push({
                    help_article_id: ele.id,
                    category_id: ele.category_id,
                    help_article_title: ele.title
                })
            })
            var params = {
                id: this.id,
                articles: arr
            }
            that.$helpApi.themeDetailAdd(params, this.id).then(res => {
                that.subLoading = false
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.cancelBtn()
                    that.$emit('affirm')
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
    }
}
</script>
<style lang='scss' scoped>
.text_main {
    width: 100%;
    .main_condition {
        width: 100%;
        padding: 0 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cond_left {
            display: flex;
            align-items: center;
            .el-button {
                padding: 8px 10px;
                margin-right: 20px;
            }
            .el-select {
                margin-right: 20px;
                width: 160px;
            }
            .el-input {
                margin-right: 20px;
                width: 160px;
            }
        }
    }
}
</style>
<style lang="scss">
/* 去掉级联选择器 单选框 点击名字可选择 */
.el-cascader-panel {
    .el-radio {
        width: 100%;
        height: 100%;
        z-index: 10;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .el-radio__input {
        visibility: hidden;
    }
    .el-cascader-node__postfix {
        top: 10px;
    }
}
</style>
<template>
    <div class="text_vue">
        <!-- 标题 -->
        <div class="vue_title">
            <span @click="back"><i class="el-icon-arrow-left"></i>返回</span>
            <p> 专题管理</p>
        </div>
        <!--  -->
        <div class="text_main">
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addTheme" size="small" icon="el-icon-plus">添加文档</el-button>
                    <el-input v-model="title" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' :clearable="true" placeholder="搜索文档名称"></el-input>
                    <el-cascader v-model="classify" :options="classifyOptions" :props="prop" :show-all-levels="false" size='small' :clearable="true" @clear='searchBtn' @change="searchBtn" placeholder="请选择分类"></el-cascader>
                </div>
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" key="theme_detail" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <el-table-column prop="id" label="ID" width="80" align="center"></el-table-column>
                    <el-table-column prop="category.name" label="分类名称"></el-table-column>
                    <el-table-column prop="help_article_title" label="标题"></el-table-column>
                    <el-table-column label="排序">
                        <template slot-scope="scope">
                            <div class="sort-input" v-show="scope.row.inputShow=='1'">
                                <div class="input-box">
                                    <el-input v-model="sort" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" size="small" @keyup.enter.native="weighChange(scope.row)"></el-input>
                                </div>
                                <span class="color1 c_p" @click="weighChange(scope.row)">确定</span>
                            </div>
                            <p v-show="scope.row.inputShow=='2'" class="sort"><span>{{scope.row.sort}}</span><i class="el-icon-edit c_p" @click="show(scope.row)"></i></p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间"></el-table-column>
                    <el-table-column class-name="last_class" label="操作" fixed="right" width="130">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="delTheme(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="material" @close='closeTheme' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">请确认是否删除专题，删除后将不可恢复</div>
            </div>
        </point-out>
        <!-- 添加/编辑 -->
        <add-theme ref="addtheme" :classifyOptions="classifyOptions" :id="id" @affirm="getList"></add-theme>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import AddTheme from '../components/theme/add_theme.vue'// 添加/编辑
export default {
    components: { PointOut, EmptyData, PagingPage, AddTheme },
    props: {},
    data() {
        return {
            // 名称
            title: '',
            // 状态
            status: '',
            statusOptions: [
                { value: 1, label: "开启" },
                { value: 2, label: "关闭" },
            ],
            // 分类
            classify: '',
            classifyOptions: [],
            // 
            list: [],
            loading: false,
            //
            page: 1,
            limit: 10,
            total: 0,
            // 
            delId: null,
            id: '',
            prop: {
                value: 'id',
                label: 'name',
                expandTrigger: 'hover',
                children: 'children',
                checkStrictly: true,
                emitPath: false
            },
            sort: "",
        };
    },


    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.getList()
            this.getCategory()
        }
    },


    methods: {
        // 修改排序
        weighChange(val) {
            if (this.sort == '') {
                this.$errMsg('排序不可为空')
                return
            }
            val.inputShow = '2'
            let obj = {
                id: val.id,
                sort: this.sort
            }
            this.$helpApi.changeWeigh(obj).then(res => {
                if (res.code == 1000) {
                    val.sort = this.sort
                    this.getList()
                    this.$succMsg(res.message)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        show(val) {
            this.list.forEach(item => {
                if (item.id == val.id) {
                    this.sort = item.sort
                    item.inputShow = '1'
                } else {
                    item.inputShow = '2'
                }
            })
        },
        // 分类
        getCategory() {
            var params = {
                parent_id: 10
            }
            this.$helpApi.categoryList(params).then(res => {
                if (res.code == 1000) {
                    let arr = res.result || []
                    this.classifyOptions = arr
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 数据列表
        getList() {
            this.loading = true
            var params = {
                page: this.page,
                limit: this.limit,
                help_article_title: this.title,
                classify_id: this.classify,
            }
            this.$helpApi.themeDetailList(params, this.id).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    let arr = res.result.details.data
                    arr.forEach(ele => {
                        ele.inputShow = "2"
                    })
                    this.list = arr;
                    this.total = res.result.details.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 筛选搜索
        searchBtn() {
            this.page = 1
            this.total = 0
            this.getList()
        },
        // 添加
        addTheme() {
            this.$refs.addtheme.openDialog()
        },
        // 删除
        delTheme(info) {
            this.delId = info.id
            this.$refs.dialogTip.openDialogBtn()
        },
        // 确定删除
        closeTheme() {
            if (this.delId != null) {
                this.$helpApi.themeDetailDel(this.delId).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg(res.message)
                        if (this.list.length == 1) {
                            this.searchBtn()
                        } else {
                            this.getList()
                        }
                        this.delId = null
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
        back() {
            this.$router.back()
        },
    }
}
</script>
<style lang='scss' scoped>
.text_vue {
    width: 100%;
    .text_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
        }
    }
    .sort-input {
        display: flex;
        align-items: center;

        .input-box {
            width: 60px;
        }

        span {
            margin-left: 10px;
        }
    }

    .sort {
        display: flex;
        align-items: center;

        span {
            margin-right: 10px;
        }
    }
    .c_p {
        cursor: pointer;
    }
}
</style>

<style lang="scss">
/* 去掉级联选择器 单选框 点击名字可选择 */
.el-cascader-panel {
    .el-radio {
        width: 100%;
        height: 100%;
        z-index: 10;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .el-radio__input {
        visibility: hidden;
    }
    .el-cascader-node__postfix {
        top: 10px;
    }
}
</style>